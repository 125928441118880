import GATSBY_COMPILED_MDX from "/home/runner/work/discretize.eu/discretize.eu/discretize-guides/fractals/molten-boss/index.md";
import {TextDivider} from '@discretize/react-discretize-components';
import NightIcon from '@mui/icons-material/Brightness3';
import TimerIcon from '@mui/icons-material/Timer';
import DayIcon from '@mui/icons-material/WbSunny';
import {Avatar, Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';
import {graphql} from 'gatsby';
import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';
import Template from '.';
import Embed from '../components/Embed';
import EmbossedCard from '../components/EmbossedCard';
import EmbossedTabs from '../components/EmbossedTabs';
import {Item} from '../components/gw2components';
import Label from '../components/Label';
import GuideHeader from '../components/navigation/GuideHeader';
import ThumbnailContext from '../state/context/ThumbnailContext';
import {getDailies} from '../state/fractals/dailies';
const useStyles = makeStyles()(theme => ({
  table: {
    padding: '4px 56px 4px 24px'
  },
  card: {
    padding: theme.spacing(2)
  },
  gridItem: {
    [theme.breakpoints.down('lg')]: {
      '&:not(:first-of-type)': {
        boxShadow: `0 -1px 0 0 ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.up('md')]: {
      '&:first-of-type': {
        paddingRight: 32
      },
      '&:not(:first-of-type)': {
        boxShadow: `-1px 0 0 0 ${theme.palette.divider}`
      }
    }
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2
  },
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  videoCardRoot: {
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden'
  },
  videoCardTitle: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    width: '100%',
    borderRadius: 0,
    padding: 0
  },
  videoCardInner: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 0,
    borderRadius: 0
  },
  videoEmbed: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    margin: 0
  }
}));
const createDefaultDescription = ({title, difficulties, cycle, record, bosses}) => `Master the ${title} fractal in Guild Wars 2 with our in-depth guide.${cycle && bosses && difficulties ? ` This ${cycle === 'Day' ? 'daytime' : 'night-time'} fractal has ${bosses} bosses and occurs at ${difficulties.length > 1 ? `levels ${difficulties.map(({level, ar}) => `${level} (${ar} AR)`).join(' and ')}.` : `level ${difficulties[0].level} with ${difficulties[0].ar} agony resistance required.`}` : ''}`;
function FractalTemplate({location, children, data: {guide: {frontmatter: {description: customDescription, title, date, difficulties, group, author, api, record, cycle, potions, sigils, consumables, bosses, image: {childImageSharp: image}}}}}) {
  const {classes} = useStyles();
  const fractalDailies = useSelector(getDailies);
  const renderHeader = () => {
    return React.createElement(GuideHeader, {
      title: title,
      subTitle: `${fractalDailies && fractalDailies.includes(api) ? 'Daily ' : ''}${group} Fractal`,
      icon: React.createElement(Avatar, {
        style: {
          width: difficulties.length * 44
        }
      }, difficulties.map(({level}) => level).join(',')),
      date: date.trim(),
      author: author
    });
  };
  const renderFrontmatter = () => {
    return React.createElement(React.Fragment, null, React.createElement(TextDivider, null, "Overview"), React.createElement(EmbossedCard, {
      classes: {
        content: classes.card
      }
    }, React.createElement(Grid, {
      container: true,
      spacing: 4
    }, React.createElement(Grid, {
      item: true,
      xs: 12,
      md: 6,
      className: classes.gridItem
    }, record && record.by && record.time && React.createElement(React.Fragment, null, React.createElement(Table, null, React.createElement(TableBody, null, React.createElement(TableRow, null, React.createElement(TableCell, {
      component: "th",
      scope: "row"
    }, " Current Record"), React.createElement(TableCell, {
      className: classes.noWrap
    }, React.createElement(TimerIcon, {
      fontSize: "inherit"
    }), " ", record.time), React.createElement(TableCell, null, React.createElement(Label, null, " by", ' ', record.by.map((r, index) => React.createElement(React.Fragment, {
      key: r.tag
    }, r.name, " [", r.tag, "]", index === record.by.length - 1 ? '' : ',', ' ', React.createElement("br")))))))), record.youtube && React.createElement(EmbossedTabs, {
      disablePadding: true,
      panes: record.youtube.map(({id, name, specialization}) => ({
        title: name,
        specialization,
        content: React.createElement(Embed, {
          youtube: id,
          className: classes.videoEmbed,
          image: image.gatsbyImageData
        })
      })),
      CardProps: {
        classes: {
          root: classes.videoCardRoot,
          title: classes.videoCardTitle,
          content: classes.videoCardInner
        }
      }
    })) || React.createElement(Typography, null, "No record")), React.createElement(Grid, {
      item: true,
      xs: 12,
      md: 6,
      className: classes.gridItem
    }, React.createElement(Table, null, React.createElement(TableBody, null, difficulties && React.createElement(TableRow, null, React.createElement(TableCell, {
      component: "th",
      scope: "row"
    }, " Difficulties"), React.createElement(TableCell, null, React.createElement(List, {
      dense: true
    }, difficulties.map(({level, ar}) => React.createElement(ListItem, {
      key: level,
      disableGutters: true,
      className: classes.listItem
    }, React.createElement(ListItemText, null, `Level ${level} `, React.createElement(Label, null, `${ar} AR`))))))), cycle && React.createElement(TableRow, null, React.createElement(TableCell, {
      component: "th",
      scope: "row"
    }, " Cycle"), React.createElement(TableCell, {
      className: classes.noWrap
    }, cycle === 'Day' && React.createElement(DayIcon, {
      fontSize: "inherit",
      className: classes.icon
    }) || cycle === 'Night' && React.createElement(NightIcon, {
      fontSize: "inherit",
      className: classes.icon
    }), ' ', cycle)), potions && React.createElement(TableRow, null, React.createElement(TableCell, {
      component: "th",
      scope: "row"
    }, " Potions"), React.createElement(TableCell, null, React.createElement(List, {
      dense: true
    }, potions.map(({id}) => React.createElement(ListItem, {
      key: id,
      disableGutters: true,
      className: classes.listItem
    }, React.createElement(ListItemText, {
      primary: React.createElement(Item, {
        id: id
      })
    })))))), sigils && React.createElement(TableRow, null, React.createElement(TableCell, {
      component: "th",
      scope: "row"
    }, " Sigils"), React.createElement(TableCell, null, React.createElement(List, {
      dense: true
    }, sigils.map(({id}) => React.createElement(ListItem, {
      key: id,
      disableGutters: true,
      className: classes.listItem
    }, React.createElement(ListItemText, {
      primary: React.createElement(Item, {
        id: id
      })
    })))))), consumables && React.createElement(TableRow, null, React.createElement(TableCell, {
      component: "th",
      scope: "row"
    }, " Consumables"), React.createElement(TableCell, null, React.createElement(List, {
      dense: true
    }, consumables.map(id => React.createElement(ListItem, {
      key: id,
      disableGutters: true,
      className: classes.listItem
    }, React.createElement(ListItemText, {
      primary: React.createElement(Item, {
        id: id
      })
    }))))))))))));
  };
  const wrapMarkdown = markdown => {
    return React.createElement(ThumbnailContext.Provider, {
      value: image.gatsbyImageData
    }, markdown);
  };
  const templateTitle = `${title} Fractal`;
  const templateDescription = customDescription || createDefaultDescription({
    title,
    cycle,
    difficulties,
    record,
    bosses
  });
  const imagePath = image && image.gatsbyImageData && image.gatsbyImageData.images.fallback.src || null;
  return React.createElement(Template, {
    location: location,
    SeoProps: {
      title: templateTitle,
      description: templateDescription,
      imagePath
    },
    markdown: children,
    renderHeader: renderHeader,
    renderFrontmatter: renderFrontmatter,
    wrapMarkdown: wrapMarkdown
  });
}
FractalTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(FractalTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query FractalQuery($id: String!) {
    guide: mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        group
        bosses
        difficulties {
          level
          ar
        }
        record {
          time
          by {
            tag
            name
          }
          youtube {
            id
            name
            specialization
          }
        }
        cycle
        potions {
          id
        }
        sigils {
          id
        }
        consumables
        api
      }
    }
  }
`;
